const productData = [
  // icons
  {
    id: 1,
    name: "Spray Tanning Course",
    description:
      "Master the art of spray tanning with our half-day, fully-accredited course in Sunless Tanning. Our expert tutors will teach you how to consult with clients, apply the product and offer aftercare advice. You’ll also get hands-on experience using the best products on the market. By the time you walk out of that room, you’ll be armed with a professional certificate and enough knowledge to take on any client!",
    price: "£99.90",
    preprice: "£225.00",
    jifen: "99 Loyalty Points ",
    jifenMoney: "£4.95",
    size: [
      "HALF DAY COURSE - (Tue 27th Aug) - 10am to 1pm",
      "HALF DAY COURSE - (Tue 27th Aug) - 2pm to 5pm",
      "HALF DAY COURSE - (Mon 16th Sep) - 2pm to 5pm",
      "HALF DAY COURSE - (Mon 21st Oct) - 2pm to 5pm",
    ],
    images: ["/images/10001.jpg"],
  },
  {
    id: 2,
    name: "Luxury Facial Course",
    description:
      "This is a 1 day intensive training course, covering the use of facial products, skincare, massage and a luxury treatments. This course is unbranded which means you are able to choose your favourite products to offer to your clients",
    price: "£125.00",
    preprice: "£250.00",
    jifen: "125 Loyalty Points ",
    jifenMoney: "£6.25",
    size: [
      "1 DAY COURSE - (Thu 08th Aug) - 10am to 5pm",
      "1 DAY COURSE - (Wed 21st Aug) - 10am to 5pm",
      "1 DAY COURSE - (Tue 03rd Sep) - 10am to 5pm",
      "1 DAY COURSE - (Fri 04th Oct) - 10am to 5pm",
      "1 DAY COURSE - (Tue 15th Oct) - 10am to 5pm",
    ],
    images: ["/images/10002.jpg"],
  },
  {
    id: 3,
    name: "Makeup Course",
    description:
      "On this course you will learn the very essentials of makeup and it’s application, and how to create day, evening, and occasion makeup looks. You will understand how to recognize skin types, and the correct products to complete a facial cleansing routine, right through to full makeup application. Use corrective and camouflage makeup techniques to create a flawless finish.",
    price: "£125.00",
    preprice: "£250.00",
    jifen: "125 Loyalty Points ",
    jifenMoney: "£6.25",
    size: [
      "1 DAY COURSE - (Thu 19th Sep) - 10am to 5pm",
      "1 DAY COURSE - (Thu 10th Oct) - 10am to 5pm",
    ],
    images: ["/images/10003.jpg"],
  },
  {
    id: 4,
    name: "Tinting Masterclass Course",
    description:
      "Learn how to brighten a client’s look and frame the face with this tinting and brow shaping course. This beauty course is not brand specific which means you have the freedom to choose which brand you want to work with!",
    price: "£125.00",
    preprice: "£250.00",
    jifen: "125 Loyalty Points ",
    jifenMoney: "£6.25",
    size: [
      "1 DAY COURSE - (Thu 08th Aug) - 10am to 5pm",
      "1 DAY COURSE - (Wed 21st Aug) - 10am to 5pm",
      "1 DAY COURSE - (Tue 03rd Sep) - 10am to 5pm",
      "1 DAY COURSE - (Fri 04th Oct) - 10am to 5pm",
      "1 DAY COURSE - (Tue 15th Oct) - 10am to 5pm",
    ],
    images: ["/images/10004.jpg"],
  },
  {
    id: 5,
    name: "Individual Eyelash Extensions (Semi Permanent) Course",
    description:
      "Join our one-day Individual Eyelash Extensions course to master semi-permanent lash application. This fully accredited, hands-on workshop teaches you to enhance natural beauty safely and effectively. Earn a professional certificate to immediately start your lash tech career. Perfect for beginners or pros looking to expand their skills. This course is not brand specific, which means you will have the freedom to choose which brand you want to work with when you build your own kit.",
    price: "£125.00",
    preprice: "£250.00",
    jifen: "125 Loyalty Points ",
    jifenMoney: "£6.25",
    size: [
      "1 DAY COURSE - (Thu 08th Aug) - 10am to 5pm",
      "1 DAY COURSE - (Wed 21st Aug) - 10am to 5pm",
      "1 DAY COURSE - (Tue 03rd Sep) - 10am to 5pm",
    ],
    images: ["/images/10005.jpg"],
  },
  {
    id: 6,
    name: "XD Volume Lash Masterclass Course",
    description:
      "Russian 2D, 3D, 4D, 5D, 6D, 7D, 8D Volume – the latest trend in the eyelash industry.",
    price: "£125.00",
    preprice: "£250.00",
    jifen: "125 Loyalty Points ",
    jifenMoney: "£6.25",
    size: [
      "1 DAY COURSE - (Thu 08th Aug) - 10am to 5pm",
      "1 DAY COURSE - (Wed 21st Aug) - 10am to 5pm",
      "1 DAY COURSE - (Tue 03rd Sep) - 10am to 5pm",
      "1 DAY COURSE - (Fri 04th Oct) - 10am to 5pm",
      "1 DAY COURSE - (Tue 15th Oct) - 10am to 5pm",
    ],
    images: ["/images/10006.jpg"],
  },
  {
    id: 7,
    name: "Micropigmentation Semi Permanent Makeup Course",
    description:
      "Micropigmentation, also known as a permanent cosmetic tattoo, involves using a mechanical hand tool controlled by machine to implant pigment into the dermal layer of the skin. The non surgical procedure is performed on the eyebrows, lips and for eyeliner.",
    price: "£2,199.00",
    preprice: " £2,299.00",
    jifen: "2199 Loyalty Points ",
    jifenMoney: "£109.95",
    size: [
      "5 DAY COURSE - (Mon 21st Oct, Tue 22nd Oct, Tue 22nd Oct, Wed 23rd Oct, Thu 24th Oct, Fri 25th Oct) - 9:30am to 4:30pm",
    ],
    images: ["/images/10007.jpg"],
  },
  {
    id: 8,
    name: "Eyebrow Micropigmentation Course",
    description:
      "Micropigmentation, also known as a permanent cosmetic tattoo, involves using a mechanical hand tool controlled by machine to implant pigment into the dermal layer of the skin. The non surgical procedure is performed on the eyebrows, lips and for eyeliner.",
    price: "£1,800.00",
    preprice: " £1,800.00",
    jifen: "1800 Loyalty Points ",
    jifenMoney: "£90.00",
    size: [
      "3 DAY COURSE - (Mon 30th Sep, Tue 01st Oct, Wed 02nd Oct) - 9:30am to 4:30pm",
    ],
    images: ["/images/10008.jpg"],
  },
  {
    id: 9,
    name: "Threading Course",
    description:
      "Threading is a traditional hair removal technique that will keep you at the top of your game with your clients. The technique originates from the Eastern world, using a simple thread to remove hair in short lines from the follicle. You will learn to thread facial hair.",
    price: "£99.00",
    preprice: "£225.00",
    jifen: "99 Loyalty Points ",
    jifenMoney: "£4.95",
    size: ["HALF DAY COURSE - (Wed 03rd Jul) - 10am to 1pm"],
    images: ["/images/10009.jpg"],
  },
  {
    id: 10,
    name: "Waxing (warm & hot wax) Course",
    description:
      "You will learn to complete waxing techniques; full leg, bikini, underarm, facial including eyebrows using warm wax.",
    price: "£125.00",
    preprice: "£250.00",
    jifen: "125 Loyalty Points ",
    jifenMoney: "£6.25",
    size: [
      "1 DAY COURSE - (Thu 08th Aug) - 10am to 5pm",
      "1 DAY COURSE - (Wed 21st Aug) - 10am to 5pm",
      "1 DAY COURSE - (Tue 03rd Sep) - 10am to 5pm",
      "1 DAY COURSE - (Fri 04th Oct) - 10am to 5pm",
      "1 DAY COURSE - (Tue 15th Oct) - 10am to 5pm",
    ],
    images: ["/images/10010.jpg"],
  },
  {
    id: 11,
    name: "Supremo Panther Black Scrub Set",
    description:
      "Introducing our new line of luxury salon scrubs, designed for the fashion-forward professional who values both comfort and style. These scrubs are crafted from a premium, stretchy material that moves with you, ensuring ease and flexibility throughout your busy day.",
    price: "£49.99",
    preprice: "£49.99",
    jifen: "50 Loyalty Points ",
    jifenMoney: "£2.50",
    size: ["MED (12)", "L (14)", "XL (16)"],
    images: ["/images/10011.jpg"],
  },
  {
    id: 12,
    name: "Supremo Blushing Nude Scrub Set",
    description:
      "Introducing our new line of luxury salon scrubs, designed for the fashion-forward professional who values both comfort and style. These scrubs are crafted from a premium, stretchy material that moves with you, ensuring ease and flexibility throughout your busy day.",
    price: "£49.99",
    preprice: "£125.99",
    jifen: "50 Loyalty Points ",
    jifenMoney: "£2.50",
    size: ["SML (10)", "MED (12)", "L (14)", "XL (16)"],
    images: ["/images/10012.jpg"],
  },
];

export default productData;
