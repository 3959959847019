import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faBookmark,
  faFlag,
  faUserGroup,
  faWandMagicSparkles,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import "../css/main.css";
import productData from "../Datas/productData";

function Main() {
  const navigate = useNavigate();

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="twoDiv">
          <img src="/mainImg/trust.webp" alt="twoImg" />
          <div className="text-container">
            <h1>Get Started</h1>
            <h2>Book Today</h2>
            <button>View Offers</button>
          </div>
        </div>
        <div className="firstDescription22">
          <div className="mainTopThree">
            <div className="glsr-summary-rating">5.0</div>
            <div className="glsr-summary-stars22">
              <div className="glsr-star-rating glsr-stars">
                <span
                  className="glsr-star glsr-star-full"
                  aria-hidden="true"
                ></span>
                <span
                  className="glsr-star glsr-star-full"
                  aria-hidden="true"
                ></span>
                <span
                  className="glsr-star glsr-star-full"
                  aria-hidden="true"
                ></span>
                <span
                  className="glsr-star glsr-star-full"
                  aria-hidden="true"
                ></span>
                <span
                  className="glsr-star glsr-star-full"
                  aria-hidden="true"
                ></span>
              </div>
              <div className="main_reviews_based_on">
                Based on <strong>6824</strong> Reviews
              </div>
            </div>
          </div>
          <div className="mainTopThree">
            <div className="glsr-summary-rating">
              <FontAwesomeIcon icon={faCheck} style={{ color: "fff" }} />
            </div>
            <div className="glsr-summary-stars22">
              <div className="main_reviews_based_on">
                Trusted by Over <strong>11000</strong>Students
              </div>
            </div>
          </div>
          <div className="mainTopThree">
            <div className="glsr-summary-rating">
              <FontAwesomeIcon icon={faBookmark} />
            </div>
            <div className="glsr-summary-stars22">
              <div className="main_reviews_based_on">
                Setting the Standard Since<strong>2012</strong>
              </div>
            </div>
          </div>
        </div>
        <div className="mainTxt">
          <div className="firstTopTxt">
            <h1>Welcome to SurePass Hair and Beauty Courses</h1>
            <p>
              Our courses offer the perfect blend of top-quality training and
              great value, all in an accredited package. This means you’ll gain
              not only skills but also industry-recognised qualifications,
              enabling you to get insured and confidently offer services to your
              clients. Our reputation among students speaks volumes about the
              high standard of our training. And guess what? We offer these
              excellent courses at some of the most competitive prices, complete
              with fantastic discounts. Dive into our offerings and start your
              journey in beauty with SurePass, where education is both effective
              and enjoyable!
            </p>
          </div>
        </div>

        <div className="fiveDiv">
          <div className="fiveDivTitle">
            <h3>Choose a Course Category</h3>
          </div>
          <ul className="brand-shops">
            {productData
              .filter((brand) => brand.id >= 1 && brand.id <= 10)
              .map((brand, index) => (
                <div className="wf-cell">
                  <li key={index}>
                    <Link to={`/shopDetails/${brand.id}`}>
                      <img alt="icon" src={brand.images[0]} />
                    </Link>
                    <div className="sixName">
                      <h3>{brand.name}</h3>
                    </div>
                    <span className="price-container">
                      <p className="original-price">{brand.preprice}</p>
                      <p className="discounted-price">{brand.price}</p>
                    </span>
                  </li>
                </div>
              ))}
          </ul>
        </div>
        <div className="vc_row">
          <div className="wpb_column">
            <div className="wbp_svg">
              <FontAwesomeIcon icon={faFlag} />
            </div>
            <div className="wbp_txt">
              <h3>Over 12 Years of Experience</h3>
              <p>
                We have been proudly supplying Hair, Beauty & Aesthetic Courses
                in the Norwich, Norfolk area for over 12 years and counting.
              </p>
            </div>
          </div>
          <div className="wpb_column">
            <div className="wbp_svg">
              <FontAwesomeIcon icon={faUserGroup} />
            </div>
            <div className="wbp_txt">
              <h3>Over 11,000 Students Trained</h3>
              <p>
                We have supplied over 12,000 Courses to over 11,000 students.
                With this vast array of experience, we can provide you with
                industry leading courses.
              </p>
            </div>
          </div>
          <div className="wpb_column">
            <div className="wbp_svg">
              <FontAwesomeIcon icon={faWandMagicSparkles} />
            </div>
            <div className="wbp_txt">
              <h3>Always Best Industry Techniques</h3>
              <p>
                Our Staff are always trained on the latest techniques and
                practises. Our courses are always evolving and are constantly
                being updated.
              </p>
            </div>
          </div>
          <div className="wpb_column">
            <div className="wbp_svg">
              <FontAwesomeIcon icon={faMedal} />
            </div>
            <div className="wbp_txt">
              <h3>Always Great Value for Money</h3>
              <p>
                We have always believed in proving great courses at a great
                price point. We guarantee you wont find better quality & value
                elsewhere.
              </p>
            </div>
          </div>
        </div>
        <div className="mainContactUs">
          <div className="tactTxt">
            <h2>Start your Career Today!</h2>
            <h2>Call 01603 360107</h2>
            <div className="mainTactUs">
              <Link to="/contactUs">Contact Us</Link>
            </div>
          </div>
          <div className="contactImg">
            <img alt="" src="/mainImg/mainContact.png" />
          </div>
        </div>
        <div className="mainTxt">
          <div className="firstTopTxt">
            <h1>Norfolk’s Premier Hair & Beauty Academy</h1>
            <p>
              Our main training centre is located in Norwich, Norfolk but we
              also occasionally offer courses in Suffolk, Cambridgeshire and
              Essex. As well as being most well known in the industry for our
              beauty courses we also have a dedicated team that specialises in
              hairdressing short courses and NVQ courses in Hairdressing. Our
              Norwich academy alone has trained 1000’s of successful students
              from far and wide which in turn have gone on to start a profitable
              career offering a combination of hair, beauty and holistic
              services. A Sure Pass adviser is always on hand to help with your
              enquiries regarding any of our short and NVQ training via email,
              telephone and live chat support. As well as a training supplier we
              also retail hair and beauty supplies and equipment which are
              available to purchase through our online shop.
            </p>
          </div>
        </div>
        <div className="fiveDiv1">
          <div className="fiveDivTitle1">
            <h3>Our Latest Reviews</h3>
          </div>
          <div className="wf-cell-all1">
            <div className="wf-cell1">
              <h4>Very informative</h4>
              <div className="glsr-summary-stars">
                <div className="glsr-star-rating glsr-stars">
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                </div>
                <div className="main_reviews">19 July 2024</div>
              </div>
              <p>
                Very friendly group, teacher Very informative and helpful.
                Really enjoyed this course, ill be back for other things. Thank
                you.
              </p>
              <p>Alison Favell</p>
            </div>
            <div className="wf-cell1">
              <h4>Absolutely loved the course!</h4>
              <div className="glsr-summary-stars">
                <div className="glsr-star-rating glsr-stars">
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                </div>
                <div className="main_reviews">19 July 2024</div>
              </div>
              <p>
                Absolutely loved the course! Claudia explained everything so
                well.
              </p>
              <p>Lianne Harding</p>
            </div>
            <div className="wf-cell1">
              <h4>Thank you!</h4>
              <div className="glsr-summary-stars">
                <div className="glsr-star-rating glsr-stars">
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                </div>
                <div className="main_reviews">19 July 2024</div>
              </div>
              <p>
                It was a really great day! Teaching was great! I feel i learnt
                alot. Thank you.
              </p>
              <p>Ethnie Hales</p>
            </div>
            <div className="wf-cell1">
              <h4>Thanks for a great day</h4>
              <div className="glsr-summary-stars">
                <div className="glsr-star-rating glsr-stars">
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                </div>
                <div className="main_reviews">19 July 2024</div>
              </div>
              <p>
                With having no hair extensions experience ive found this course
                amazing. Claudia was so thorough and patient. Thanks for a great
                day
              </p>
              <p>Toni Kimpton</p>
            </div>
            <div className="wf-cell1">
              <h4>Claudia is amazing</h4>
              <div className="glsr-summary-stars">
                <div className="glsr-star-rating glsr-stars">
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                </div>
                <div className="main_reviews">19 July 2024</div>
              </div>
              <p>
                Claudia was amazing and kind throughout. The course was
                informative, practical and very laid back which is perfect if
                you are anxious. i thoroughly enjoyed myself and am so grateful.
              </p>
              <p>Kathy Francksen</p>
            </div>
            <div className="wf-cell1">
              <h4>Amazing course</h4>
              <div className="glsr-summary-stars">
                <div className="glsr-star-rating glsr-stars">
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="glsr-star glsr-star-full"
                    aria-hidden="true"
                  ></span>
                </div>
                <div className="main_reviews">19 July 2024</div>
              </div>
              <p>
                Amazing course, very thorough and nothing was left unspoken
                about. Cant wait to come back for more courses.
              </p>
              <p>Alison Favell</p>
            </div>
          </div>
        </div>
        <div className="upb_row_bg">
          <div className="wpb_wrapper">
            <h3>Luxury Clothing Range</h3>
            <p>
              Not only do we offer great hair and beauty courses, we also offer
              luxury stylish salon clothing too.
            </p>
          </div>
          <div className="arrDiv">
            <img alt="" src="/mainImg/arr.webp" className="arrImg" />
            <div>
              <ul className="brand-shops">
                {productData
                  .filter((brand) => brand.id >= 11 && brand.id <= 12)
                  .map((brand, index) => (
                    <div className="arr-cell">
                      <li key={index}>
                        <Link to={`/shopDetails/${brand.id}`}>
                          <img alt="icon" src={brand.images[0]} />
                        </Link>
                        <div className="sixName11">
                          <h3>{brand.name}</h3>
                        </div>
                        <span className="price-container11">
                          <p className="discounted-price11">
                            {brand.price} inc VAT
                          </p>
                        </span>
                      </li>
                    </div>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="blogDiv">
          <div className="wpb_wrapper11">
            <h3>Our Latest Blogs</h3>
          </div>
          <div className="blogImgAll">
            <div className="blogDivImg">
              <div className="blogImg1">
                <img alt="" src="/mainImg/bolg1.jpg" />
                <div className="blogTxt">
                  <h3>How to be a Great Hairdresser</h3>
                  <p>
                    How to be a Great Hairdresser Being a good stylist is much
                    more than just having the right tools and techniques for the
                    job. It’s a passion for the craft,…
                  </p>
                </div>
              </div>
            </div>
            <div className="blogDivImg">
              <div className="blogImg1">
                <img alt="" src="/mainImg/blog2.jpg" />
                <div className="blogTxt">
                  <h3>How to Start a Mobile Beauty Business</h3>
                  <p>
                    How to Start a Mobile Beauty Business starting a business in
                    the UK can be dull, monotonous and confusing. But we’re here
                    to make it fun, exciting, and extremely perplexing.…
                  </p>
                </div>
              </div>
            </div>
            <div className="blogDivImg">
              <div className="blogImg1">
                <img alt="" src="/mainImg/blog3.jpg" />
                <div className="blogTxt">
                  <h3>Tips on Keeping Your Skin Soft and Vibrant</h3>
                  <p>
                    Tips on Keeping Your Skin Soft and Vibrant When it comes to
                    velvety, radiant skin, a thoughtful and consistent skincare
                    routine is necessary. Your skin is your body’s largest
                    organ…
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="playShopDiv">
          <img alt="" src="/mainImg/playshop.webp" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
