import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { useNavigate, Link } from "react-router-dom";
import { CartContext } from "../Component/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const [size, setSize] = useState(null);
  const [quantities, setQuantities] = useState(1);
  const [selectedSize, setSelectedSize] = useState(null);
  const { addToCart } = useContext(CartContext);

  const selectSize = (size) => {
    setSize(size);
    setSelectedSize(size);
  };

  const handleAddToCart = () => {
    if (!size) {
      alert("Please select a size before adding to cart.");
      return;
    }

    addToCart(product.id, quantities, size);
    navigate("/cartpage");
  };
  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="main-container">
      <Header />
      <div className="shopDiv">
        <div className="product-detail">
          <div className="product-images">
            <div className="carousel">
              {product.images.map((image, index) => (
                <img key={index} src={image} alt={`${product.name}`} />
              ))}
            </div>
            <div className="playShopDiv1">
              <img alt="" src="/mainImg/playshop.webp" />
            </div>
            <div className="goBackToShop">
              <Link to="/">{"<"}&nbsp;&nbsp;&nbsp;Go Back To Shopping.</Link>
            </div>
          </div>
          <div className="product-info">
            <div className="rightTitle">
              <h1>{product.name}</h1>
            </div>
            <div className="limitDiv">
              <span className="limitOffer">Limited Offer: </span>
              <span className="prePrice">
                Was
                {product.preprice}
              </span>
              <span className="shouldbepercent">
                <FontAwesomeIcon icon={faTag} spin /> 56% OFF
              </span>
            </div>
            <div className="priceDiv">
              <span className="nowPrice">Now Only</span>
              <span className="now-price">{product.price}</span>
              <small>inc VAT</small>
            </div>
            <span className="hiddenSpan">
              <strong>No Hidden Charges:</strong> Includes VAT & All Fees
            </span>
            <div className="yith-par-message-variation">
              <img alt="" src="/mainImg/badge.svg" />
              Purchase this and earn<strong>{product.jifen}</strong>worth
              <strong>{product.jifenMoney}</strong>
            </div>
            <div className="woocommerce-product-details__short-description">
              <p>{product.description}</p>
            </div>

            <div className="template_content">
              <div className="product-sizes">
                <h3>Choose a Course Date:</h3>
                <ul>
                  {product.size.map((size, index) => (
                    <li
                      key={index}
                      onClick={() => selectSize(size)}
                      className={selectedSize === size ? "selected" : ""}
                    >
                      {size}
                      <FontAwesomeIcon
                        icon={selectedSize === size ? faCheck : faXmark}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="btnDiv">
                <button className="add-to-cart" onClick={handleAddToCart}>
                  Add to Basket
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;
