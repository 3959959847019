import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";
import { Link } from "react-router-dom";

function ContactUs() {
  return (
    <div className="main-container">
      <Header />
      <div className="grayMid">
        <p>Home/contact</p>
      </div>
      <div className="contactAll">
        {/* left */}
        <div className="contactLeft">
          {/* left-top */}
          <div className="contactLeftTop">
            <div className="contactLeft1Title">
              <h2>CONTACT US</h2>
              <h4>SurePass Solutions Ltd</h4>
              <soan>
                8 Aylsham Road
                <br />
                Norwich
                <br />
                Norfolk
                <br />
                NR3 3HQ
                <br />
                United Kingdom
              </soan>
            </div>
            <div className="contactLeft2Title">
              <strong>Line 1: </strong>01603 360107
              <br />
              <strong>Line 2:</strong>01603 394098
              <br />
              <strong>Office Hours:</strong>9 - 5 : Mon - Fri
              <br />
              <br />
              <strong>Company Number: </strong>09301224
              <br />
              <strong>VAT Number: </strong>GB228299575
              <br />
            </div>
          </div>
          {/* left-mid */}
          <div className="contactLeftMid">
            <div className="contactLeftMidTxt">
              <h2>Parking</h2>
            </div>
            <div>
              <p>
                The nearest all day parking is located at Anglia Square. It is
                just 300 meters away (5 minutes walk). You can pay by Cash
                (Coins), Mobile App, or<a> Book Online</a>
              </p>
            </div>
          </div>
          {/* left-bot */}
          <div className="contactLeftBottom">
            <div className="contactLeftBottomTitle">
              <h2>Find Us</h2>
            </div>
            <div className="contactLeftBottomTop">
              <div className="contactLeftBottom1">
                <div className="contactLeftBottom1Top">
                  <input type="text" placeholder="Enter Post Code" />
                  <button>Get Direction</button>
                </div>
                <div className="whatDiv">
                  <strong>What3words: </strong>
                  <Link to="">///change.safe.badge</Link>
                </div>
              </div>
              <div className="contactLeftBottom2">
                <img alt="" src="/images/contact1.webp" />
              </div>
            </div>
          </div>
        </div>
        {/* right */}
        <div className="contactRight">
          <div className="contactRightTxt">
            <h2>Send Us a Message</h2>
            <p>If you have any queries we will be pleased to answer them.</p>
            <div className="contactRightForm">
              <lable>Your Name:</lable>
              <br />
              <input type="text" placeholder="Enter Your Name" />
              <br />
              <lable>Your Email:</lable>
              <br />
              <input type="text" placeholder="Enter Your Email" />
              <br />
              <lable>Your Message:</lable>
              <br />
              <textarea placeholder="Enter Your Message" />
              <br />
              <button>Send Message</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
