import "../css/header.css";
import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faBasketShopping,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, Link } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

function Header() {
  const navigate = useNavigate();
  const handleAddToCart = () => {
    navigate("/cartpage");
  };
  const { cartItems } = useContext(CartContext);

  return (
    <div className="header-container">
      <div className="allDiv">
        <div className="headerDiv">
          <div className="leftImg">
            <img alt="" src="/headImg/surepasslogo.webp" />
          </div>
          <div className="rightT">
            <div className="welcomeGuest">Welcome, Guest</div>
            <div className="rightTime">
              <div className="opening_hours">
                Monday - Friday&nbsp;&nbsp; 09:00 - 17:00
              </div>
              <div className="contact_number">01603 360107</div>
            </div>
          </div>
        </div>
        <div className="headerMid">
          <nav className="navigation">
            <ul className=" main-nav">
              <li className="menu-item">
                <Link to="/" className="homeToA">
                  <FontAwesomeIcon icon={faHouse} />
                  Home
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/contact">Contact Us</Link>
              </li>
              <li className="menu-item">
                <Link to="/aboutUs">About Us</Link>
              </li>
            </ul>
          </nav>
          <div className="menu-shop">
            <FontAwesomeIcon
              icon={faBasketShopping}
              onClick={handleAddToCart}
            />
            <div className="shop-basket">
              <Link to="#">
                <FontAwesomeIcon icon={faUser} />
                <span>Student Portal</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
