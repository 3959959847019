import React, { useContext, useState } from "react";
import { CartContext } from "../Component/CartContext";
import "../css/checkout.css";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate, Link } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const CheckOut = () => {
  const { cartItems } = useContext(CartContext);
  const [country, setCountry] = useState("US");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const StyledTableContainer = styled(TableContainer)({
    minWidth: 600,
  });

  const StyledImage = styled("img")({
    width: 112,
    height: 124,
    objectFit: "cover",
  });

  const StyledTableCell = styled(TableCell)({
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    fontSize: "16px",
    borderRight: "1px dotted #ccc",
  });

  const handlePlaceOrder = () => {
    const newErrors = {};

    if (!email) newErrors.email = "Email is required";
    if (!country) newErrors.country = "Country is required";
    if (!phone) newErrors.phone = "Phone number is required";
    if (!firstName) newErrors.firstName = "First name is required";
    if (!lastName) newErrors.lastName = "Last name is required";
    if (!address) newErrors.address = "Address is required";
    if (!city) newErrors.city = "City is required";
    if (!postalCode) newErrors.postalCode = "Postal code is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      alert("Order placed!");
      navigate("/paysuccess");
    }
  };

  const isFormValid = () => {
    return (
      email &&
      country &&
      phone &&
      firstName &&
      lastName &&
      city &&
      postalCode &&
      address
    );
  };

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      const price = parseFloat(product.price.replace("£", ""));
      return total + price * item.quantity;
    }, 0);
  };

  return (
    <>
      <Header />
      <div className="main-containerCheck">
        <div className="cart-header">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#fff" }} />
          <FontAwesomeIcon icon={faTrophy} />
          <p>
            If you proceed to checkout, you will earn&nbsp;
            <strong>{calculateTotal().toFixed(2)}</strong>&nbsp;Points!
          </p>
        </div>
        <div className="check-custom-checkout-page">
          <div className="left">
            <div className="check-order-info">
              <form>
                <div className="form-group form-group-half">
                  <label htmlFor="first-name">First name *</label>
                  <input
                    type="text"
                    id="first-name"
                    name="first-name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                  {errors.firstName && (
                    <p className="error">{errors.firstName}</p>
                  )}
                </div>
                <div className="form-group form-group-half">
                  <label htmlFor="last-name">Last name *</label>
                  <input
                    type="text"
                    id="last-name"
                    name="last-name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                  {errors.lastName && (
                    <p className="error">{errors.lastName}</p>
                  )}
                </div>
                <div className="form-group form-group-half">
                  <label htmlFor="company-name">Company name (optional)</label>
                  <input type="text" id="company-name" name="company-name" />
                </div>
                <div className="form-group form-group-half">
                  <label htmlFor="dob">Date of Birth *</label>
                  <input type="date" id="dob" name="dob" required />
                </div>
                <div className="form-group">
                  <label htmlFor="address">Address *</label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  />
                  {errors.address && <p className="error">{errors.address}</p>}
                </div>
                <div className="form-group form-group-half">
                  <label htmlFor="city">Town / City *</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                  {errors.city && <p className="error">{errors.city}</p>}
                </div>
                <div className="form-group form-group-half">
                  <label htmlFor="county">County *</label>
                  <input type="text" id="county" name="county" required />
                </div>
                <div className="form-group form-group-half">
                  <label htmlFor="postalCode">Postcode *</label>
                  <input
                    type="text"
                    id="postalCode"
                    name="postalCode"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    required
                  />
                  {errors.postalCode && (
                    <p className="error">{errors.postalCode}</p>
                  )}
                </div>
                <div className="form-group form-group-half">
                  <label htmlFor="phone">Phone Number *</label>
                  <input
                    country={country.toLowerCase()}
                    value={phone}
                    onChange={(number) => setPhone(number)}
                    className="check-input"
                    style={{ width: "100%" }}
                  />
                  {errors.phone && <p className="error">{errors.phone}</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email address *</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="notes">Notes (optional)</label>
                  <textarea id="notes" name="notes" rows="4"></textarea>
                </div>
              </form>
            </div>
          </div>

          <div className="right">
            <div className="check-order-summary">
              {cartItems.length > 0 && (
                <div className="cart-summary">
                  <div className="rightSum">
                    <h2 className="basketDiv">Your order</h2>

                    <table className="summary-details">
                      <tbody className="sumTbody">
                        <tr className="sumTr">
                          <th className="subtotalTh">Subtotal</th>
                          <th className="subtotalTxt">
                            £{calculateTotal().toFixed(2)}
                          </th>
                        </tr>
                        <tr className="sumTr">
                          <th className="totalTh">Total(inc VAT)</th>
                          <th className="totalTxt">
                            £{calculateTotal().toFixed(2)}
                          </th>
                        </tr>
                        <tr className="payTr">
                          <th>
                            <img alt="" src="/footImg/pay.svg" />
                            <p>Pay in 3 interest-free payments</p>
                          </th>
                        </tr>

                        <div className="cartDebit">
                          <p>Credit/Debit Cards</p>
                          <div className="cartImg">
                            <img alt="" src="/footImg/cart1.svg" />
                            <img alt="" src="/footImg/cart2.svg" />
                            <img alt="" src="/footImg/cart3.svg" />
                            <img alt="" src="/footImg/cart4.svg" />
                            <img alt="" src="/footImg/cart5.svg" />
                          </div>
                        </div>
                        <input
                          type="text"
                          placeholder="Cart number(MM/YY/ CVC)"
                        />
                      </tbody>
                    </table>
                    <p>
                      By proceeding, you agree that your personal data can be
                      used to process your order, support your experience
                      throughout this website, and for other purposes described
                      in our<Link to="/privacy">privacy policy.</Link>
                    </p>
                    <button
                      className="check-checkout-button"
                      onClick={handlePlaceOrder}
                      disabled={!isFormValid()}
                    >
                      Pay Now
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default CheckOut;
