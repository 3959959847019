import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faFolder } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <>
      <div className="bottom-container">
        <div className="botLeft">
          <div className="comImfo">
            <h3 className="comImfoH3">Company Information</h3>
            <p>
              SurePass Solutions Ltd
              <br />
              8 Aylsham Road
              <br />
              Norwich, Norfolk, NR3 3HQ
              <br />
              United Kingdom
            </p>
            <p>
              Company Number: 09301224
              <br />
              VAT Number: GB228299575
            </p>
          </div>
          <div className="comImfo">
            <ul>
              <h3 className="comImfoH3">Company Information</h3>
              <li>
                <Link to="/contact">
                  <FontAwesomeIcon icon={faEnvelope} />
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/privacy">
                  <FontAwesomeIcon icon={faFolder} />
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/aboutUs">
                  <FontAwesomeIcon icon={faFolder} />
                  About Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="comImfo">
            <h3 className="comImfoH3">Opening Hours</h3>
            <tbody>
              <tr>
                <td>Monday</td>
                <td>9:00 AM — 5:00 PM</td>
              </tr>
              <tr>
                <td>Tuesday</td>
                <td>9:00 AM — 5:00 PM</td>
              </tr>
              <tr>
                <td>Wednesday</td>
                <td>9:00 AM — 5:00 PM</td>
              </tr>
              <tr>
                <td>Thursday</td>
                <td>9:00 AM — 5:00 PM</td>
              </tr>
              <tr>
                <td>Friday</td>
                <td>9:00 AM — 5:00 PM</td>
              </tr>
              <tr>
                <td>Saturday</td>
                <td>Closed</td>
              </tr>
              <tr>
                <td>Sunday</td>
                <td>Closed</td>
              </tr>
            </tbody>
          </div>
        </div>
        <div className="legal">
          <div>
            <img alt="" src="/footImg/payment-icons.png" />
          </div>
          <div className="footLeftBot">
            <div className="legalLeft">©2024 SurePass Solutions Ltd</div>
            <div className="footer_bar_seperator">l</div>
            <div className="webdesign_by_perfect">
              <Link to="https://www.perfect.uk">Web Design by Perfect.uk</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
