import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";

function AboutUs() {
  return (
    <div className="main-container">
      <Header />
      <div className="grayMid">
        <p>Home/about</p>
      </div>
      <div className="helpDiv">
        <div className="helpUs">
          <h1>About Us</h1>
        </div>

        <div className="refundMid">
          <div className="helpTxt">
            <p>
              Welcome to Surepass Beauty Academy established in 2010. Surepass
              Beauty Academy is a recognised, accredited training academy with
              VTCT and ABT. We offer Hair, Beauty, and Holistic training
              courses. We welcome all levels of learners, from the complete
              novice to the beauty tutor wanting to expand their skills. We have
              experienced tutors on hand to discuss your individual learning
              needs, we can work with you in a group training session or on a
              1:1 training session. We are here to work with you outside of the
              box!
            </p>
            <p>
              We have various locations so please take a look at the Training
              Locations to see where you can train. In the meantime please find
              below our head office address where ALL correspondence is to be
              sent.
            </p>
            <p>8 Aylsham Road</p>
            <p>Norwich</p>
            <p>NR3 3HQ</p>
            <p>PUBLIC TRANSPORT:</p>
            <p>
              Our head office location academy benefits from having a bus route
              across the road, and just a 5-minute taxi drive from the Norwich
              train station. (ABC taxis have confirmed with us the cost is just
              £6 from the train station to us !)
            </p>
            <p>PARKING:</p>
            <p>
              Parking is available at the local pay and display located just a 2
              minute walk away on Anglia Square Car Park
            </p>
            <p>
              Please do not park in surrounding private residential parking
              areas, you are likely to be clamped
            </p>
            <p>DISABLED PARKING:</p>

            <p>Please contact us to discuss your needs.</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUs;
